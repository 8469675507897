$.mask.definitions['9'] = '';
$.mask.definitions['n'] = '[0-9]';
$(function(){
  $(".phone-input").mask("+998 nn nnn nn nn");
  // $("#phone-input2").mask("+998 nn nnn nn nn");
  // $("#phone-input3").mask("XXXX XXXX XXnn nnnn");
  // $("#phone-input4").mask("nn/nn");
  // $("#phone-input5").mask("nnnn");
});

// humbergers
function humbergers() {
  var hamburgers = document.querySelector(".hamburger");
  hamburgers.addEventListener(
    "click",
    function () {
      this.classList.toggle("is-active");
      document.querySelector(".navbar-navs").classList.toggle("is-active");
    },
    false
  );
}
humbergers();

$(document).ready(function () {
  $(".navbar-navs a").on("click", function () {
    $(".hamburger").removeClass("is-active");
    $(".navbar-navs").removeClass("is-active");
  });
});

$().fancybox({
  selector: ".swiper-slide a",
  thumbs: {
    autoStart: true,
  },
});

var swiper_gallery = new Swiper(".swiper-gallery", {
  navigation: {
    nextEl: ".swiper-button-next-gallery",
    prevEl: ".swiper-button-prev-gallery",
  },
  slidesPerView: 4,
  spaceBetween: 10,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  breakpoints: {
    0: {
      slidesPerView: 1.3,
      spaceBetween: 10,
    },
    600: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    640: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    992: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  },
});

var swiper_comment = new Swiper(".swiper-comment", {
  navigation: {
    nextEl: ".swiper-button-next-comment",
    prevEl: ".swiper-button-prev-comment",
  },
});

new WOW().init();



var swiper = new Swiper('.swiper-gallery-two', {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});



$('#closeBtn').click(function() {
  $('#modal').modal('hide');
});